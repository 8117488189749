import React from 'react';
import Favicon from '../Favicon';
import HeaderBanner from '@/ui/shared/components/Layout/HeaderBanner';
import CheckAccessPermissions from '@/users/application/Session/CheckAccessPermissions';

interface IProps {
  children: React.ReactElement,
  withBanner?: boolean,
  onInit: () => void,
}

const Layout = (
  {
    children,
    withBanner,
    onInit,
  }: IProps,
) => (
  <>
    <Favicon />
    { withBanner && (
      <HeaderBanner shouldUseContentWrapAsScrollable />
    ) }
    { onInit ? (<CheckAccessPermissions onInit={ onInit }>{ children }</CheckAccessPermissions>) : children }
  </>
);

export default Layout;
