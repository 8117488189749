import cn from 'classnames';
import React from 'react';
import { Link } from '@/ui/shared/components/Next/Link';
import finsightLogo from '@/ui/shared/assets/logo/finsightLogo.svg';
import { useGlobalErrorContext } from '@/Framework/ErrorHandling/GlobalError/GlobalErrorContext';
import GlobalError from '@/Framework/ErrorHandling/GlobalError';
import { Spinner, Icon } from '@dealroadshow/uikit';
import headerStyles from '@/ui/shared/styles/headers.scss';
import styles from './usersContent.scss';

interface IProps {
  children?: React.ReactNode,
  title?: string,
  subtitle?: string | React.ReactNode,
  className?: string,
  titleClassName?: string,
  subTitleClassName?: string,
  contentClassName?: string,
  innerContentClassName?: string,
  showPreloader?: boolean,
  footerContent?: React.ReactNode,
  logo?: string,
  logoLink?: string,
  isWide?: boolean,
  isExtraWide?: boolean,
  icon?: typeof finsightLogo,
  iconClassName?: string,
  wrapperClassName?: string,
  copyrightClassName?: string,
  withLogo?: boolean,
}

const UsersContent = ({
  children,
  title,
  subtitle,
  className,
  titleClassName,
  subTitleClassName,
  contentClassName,
  innerContentClassName,
  showPreloader,
  footerContent,
  logo = finsightLogo,
  logoLink,
  isWide = false,
  isExtraWide = false,
  icon,
  iconClassName,
  wrapperClassName,
  copyrightClassName,
  withLogo = true,
}: IProps) => {
  const containerCls = cn(
    styles.container,
    className,
    {
      [styles.isWide]: isWide,
      [styles.isExtraWide]: isExtraWide,
    },
  );

  const logoElement = logoLink ? (
    <Link target="_blank" to={ logoLink } className={ styles.logoWrp }>
      <img src={ logo } alt="Logo" />
    </Link>
  ) : (
    <div className={ styles.logoWrp }>
      <img src={ logo } alt="Logo" />
    </div>
  );

  const { isGlobalErrorVisible } = useGlobalErrorContext();

  return (
    <div className={ cn(styles.wrp, wrapperClassName) }>
      <div className={ styles.aligner }>
        <div className={ containerCls }>
          { withLogo && (
            <div className={ styles.logoWrp }>
              { logoElement }
            </div>
          ) }
          <div className={ cn('contentWrp', styles.contentWrp) }>
            { showPreloader && <Spinner overlay /> }
            <div className={ cn(styles.content, contentClassName) }>
              { icon && (
                <div>
                  <Icon type={ icon } className={ cn(styles.icon, iconClassName) } />
                </div>
                ) }
              { title && (
                <h1 className={ cn('title', headerStyles.isH2, styles.title, titleClassName) }>{ title }</h1>
              ) }
              { subtitle && !isGlobalErrorVisible && (
                <div className={ cn('subtitle', styles.subtitle, subTitleClassName) }>
                  { subtitle }
                </div>
                ) }
              <GlobalError className={ cn(styles.subtitle, styles.errorMessage) } />
              <div className={ cn(styles.innerContent, innerContentClassName) }>
                { children }
              </div>
            </div>
            { !!footerContent && (
              <div className={ styles.footer }>
                { footerContent }
              </div>
              ) }
          </div>
          <div className={ cn(styles.copyright, copyrightClassName) }>
            © FINSIGHT Group Inc
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersContent;
