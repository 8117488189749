import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

type RetainQueryLinkProps = {
  href: string | { pathname: string, query?: object },
  className?: string,
  children?: React.ReactNode,
  queryItems?: string[] | null,
};

const RetainQueryLink = (
  {
    href,
    className,
    children,
    queryItems = ['callbackUrl', 'tenant'],
    ...props
  }: RetainQueryLinkProps,
) => {
  const router = useRouter();
  const pathname = typeof href === 'object' ? href.pathname : href;
  const nextPath = pathname.split('?')[0];
  const nextPathQuery = new URLSearchParams(pathname.split('?')[1]);

  const query =
    typeof href === 'object' && typeof href.query === 'object'
      // @ts-ignore
      ? new URLSearchParams(href.query)
      : nextPathQuery;

  const getQuery = (queries) => {
    if (queryItems) {
      queryItems.forEach((item) => {
        if (queries[item]) {
          query.set(item, queries[item]);
        }
      });
    }

    return new URLSearchParams({
      ...(queryItems ? null : queries),
      ...Object.fromEntries(nextPathQuery),
      ...Object.fromEntries(query),
    }).toString();
  };

  return (
    <Link
      href={ {
        pathname: nextPath,
        query: getQuery(router.query),
      } }
      legacyBehavior
    >
      <a className={ className } { ...props }>
        { children }
      </a>
    </Link>
  );
};

export default RetainQueryLink;
